import { asyncWithLDProvider, useFlags } from 'launchdarkly-react-client-sdk';
import { useState, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';

/**
 * Initialize the user key for LaunchDarkly.
 * @return {string} - The user key.
 */
const initializeUserKey = () => {
  const LD_USER_KEY = 'ld_user_key';
  const userKey = localStorage.getItem(LD_USER_KEY) || uuid();
  localStorage.setItem(LD_USER_KEY, userKey);

  return userKey;
};

const notAnoymousLocalFeatureFlags = [];

const customContextProperties = {
  userAgent: navigator.userAgent,
};

export const useLaunchDarkly = () => {
  const [ldReady, setLdReady] = useState(false);
  const LDProvider = useRef(null);
  useEffect(() => {
    const launchDarklyConfig = {
      clientSideID: import.meta.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
      context: {
        kind: 'user',
        anonymous: notAnoymousLocalFeatureFlags.some(flag => !flag),
        key: initializeUserKey(),
        custom: customContextProperties,
      },
      options: {
        bootstrap: 'localStorage',
      },
    };

    asyncWithLDProvider(launchDarklyConfig).then(ldProvider => {
      setLdReady(true);
      LDProvider.current = ldProvider;
    });
  }, []);

  return { ldReady, LDProvider };
};

/**
 * Custom hook to check if a feature flag is enabled.
 * @param {FeatureFlagsEnum} flagName - The name of the feature flag to check.
 * @returns {boolean} - Whether the feature flag is enabled.
 */
export const useFeatureFlag = flagName => {
  const flags = useFlags();

  return flags[flagName] || false;
};
